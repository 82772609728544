import { Trans } from "@lingui/macro";
import { Box, Grid, Stack, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import styled from "@emotion/styled";
import Container from "../../../components/Container";
import HeroLanding from "../../../components/HeroLanding";
import SecondaryButton from "../../../components/SecondaryButton";
import Seo from "../../../components/seo";
import Header from "../../../components/Header";
import ProductModal from "../../../components/ProductModal";
import { theme } from "../../../themeV2";
import sec1Img from "../../../images/solutions/regulatory/fund-registration/sec-1.png";
import sec2Img from "../../../images/solutions/regulatory/fund-registration/sec-2.png";
import sec2Icon1 from "../../../images/solutions/regulatory/fund-registration/sec-2_icon-1.png";
import sec2Icon2 from "../../../images/solutions/regulatory/fund-registration/sec-2_icon-2.png";
import sec3Img from "../../../images/solutions/regulatory/fund-registration/sec-3.png";
import heroVideoPoster from "../../../images/solutions/regulatory/fund-registration/hero-video-poster.png";
const heroVideo = "https://app.allfunds.com/docs/cms/hero_video_d204d1387e.mp4";

const metadata = {
  title: "Fund Registration",
  description:
    "Allfunds offers comprehensive support to expand your fund distribution to new markets, providing you with the crucial information and registration services needed to successfully sell your funds globally.",
};

const SEC_2_ITEMS = [
  {
    icon: sec2Icon1,
    title: <Trans>Extensive Global Coverage</Trans>,
    description: (
      <Trans>
        Our registration services cover more than 70 countries, including EU-EEA countries,
        APAC, Latam, and the Middle East, providing unmatched access to diverse markets.
      </Trans>
    ),
  },
  {
    icon: sec2Icon2,
    title: <Trans>Seamless Integration and Support</Trans>,
    description: (
      <Trans>
        Our platform integrates seamlessly with your existing operations, offering
        continuous support to manage registrations efficiently.
      </Trans>
    ),
  },
];

function FundRegistration() {
  const [showModal, setShowModal] = useState(false);

  const renderRequestButton = () => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => setShowModal(true)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const preTitle = ({
    color = `${theme.palette.colors.orange.dark}`,
    text,
    align = "center",
  }) => {
    return (
      <Typography
        fontWeight={700}
        color={color}
        textAlign={align}
        fontSize={{ xs: "18px", sm: "20px" }}
      >
        {text}
      </Typography>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Seo title={metadata.title} description={metadata.description} />
      <Header />
      <HeroLanding
        title={<Trans>Boost your distribution strategy and access new markets</Trans>}
        description={
          <Trans>
            Allfunds offers comprehensive support to expand your fund distribution to new markets, providing you with
            the crucial information and registration services needed to successfully sell your funds globally.
          </Trans>
        }
        handleOnclickInfo={() => setShowModal(true)}
        isVideo={true}
        multimedia={heroVideo}
        videoPoster={heroVideoPoster}
        widthImage="100%"
        kind="Fund Registration"
        kindColor={theme.palette.colors.orange.dark}
        grid={{
          columnLeft: { md: 6.5 },
          columnRight: { md: 5.5 },
        }}
      />
      <Container component="section" py={{ xs: 8, sm: 6, md: 10, lg: 8 }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          flexDirection="row-reverse"
        >
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <Stack spacing={1} component="header">
                {preTitle({ text: "Fund Registration", align: "left" })}
                <Typography variant="h2">
                  <Trans>One Stop Shop Solution for Fund Registration Services</Trans>
                </Typography>
              </Stack>
              <LightTypography>
                <Trans>Navigating the complexities of fund registration can be daunting, but Allfunds simplifies this
                  process with our all-in-one solution. Our platform provides:</Trans>
              </LightTypography>
              <ul style={{ padding: "0 0 0 18px", opacity: "0.8" }}>
                <li>
                  <Trans>
                    <strong>Comprehensive Regulatory Information:</strong> Stay informed with the latest regulatory updates and requirements
                    needed to register your funds in any new country.
                  </Trans>
                </li>
                <li>
                  <Trans>
                    <strong>In-Depth Insights on Regulatory Changes:</strong> Keep your finger on the pulse of new regulatory requirements
                    that could impact your fund distribution strategy, ensuring compliance and seamless market entry.
                  </Trans>
                </li>
                <li>
                  <Trans>
                    <strong>Tailored Guidance and Support:</strong> Benefit from personalized assistance and expert advice tailored to your
                    specific needs and market conditions.
                  </Trans>
                </li>
              </ul>
              <Box
                display="flex"
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                {renderRequestButton()}
              </Box>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            component="figure"
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            <img
              src={sec1Img}
              alt="Fund Registration"
              width="100%"
              height="100%"
            />
          </Grid>
        </Grid>
      </Container>
      <Container component="section" py={{ xs: 8, sm: 6, md: 10, lg: 8 }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <Stack spacing={1} component="header">
                {preTitle({ text: "Fund Registration", align: "left" })}
                <Typography variant="h2">
                  <Trans>Supporting UCITS and AIF Fund Managers Worldwide</Trans>
                </Typography>
              </Stack>
              <LightTypography>
                <Trans>With Allfunds, fund managers of UCITS and AIF have the tools and support needed to reach a global audience. Our services include:</Trans>
              </LightTypography>
              <Box
                display="grid"
                gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr" }}
                gap="36px"
              >
                {SEC_2_ITEMS.map(({ icon, title, description }) => (
                  <>
                    <Stack spacing={1}>
                      <img
                        width={48}
                        height={48}
                        src={icon}
                        alt={title}
                        style={{ objectFit: "contain" }}
                      />
                      <Typography fontWeight={700}>{title}</Typography>
                      <LightTypography>{description}</LightTypography>
                    </Stack>
                  </>
                ))}
              </Box>
              <Box
                display="flex"
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                {renderRequestButton()}
              </Box>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            component="figure"
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            <img
              src={sec2Img}
              alt="Fund Registration"
              width="100%"
              height="100%"
            />
          </Grid>
        </Grid>
      </Container>
      <Container component="section" py={{ xs: 8, sm: 6, md: 10, lg: 8 }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          flexDirection="row-reverse"
        >
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <Stack spacing={1} component="header">
                {preTitle({ text: "Fund Registration", align: "left" })}
                <Typography variant="h2">
                  <Trans>Control Dashboard with Task Management System</Trans>
                </Typography>
              </Stack>
              <LightTypography>
                <Trans>Allfunds’ platform features a powerful control dashboard designed to streamline your fund registration process. Key features include:</Trans>
              </LightTypography>
              <ul style={{ padding: "0 0 0 18px", opacity: "0.8" }}>
                <li><Trans>Regulatory Reporting Corner</Trans></li>
                <li><Trans>One Unique Platform for Regulatory Solutions</Trans></li>
                <li><Trans>Full End-to-End Registration Process</Trans></li>
                <li><Trans>Fund Registration Oversight</Trans></li>
                <li><Trans>Advanced Task Management</Trans></li>
              </ul>
              <Box
                display="flex"
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                {renderRequestButton()}
              </Box>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            component="figure"
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            <img
              src={sec3Img}
              alt="Fund Registration"
              width="100%"
              height="100%"
            />
          </Grid>
        </Grid>
      </Container>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Fund Registration"
          product="fund-registration"
          hasTemplate={false}
        />
      )}
    </ThemeProvider>
  );
}

const LightTypography = styled(Typography)`
  opacity: 0.8;
`;

export default FundRegistration;
